import Icon from '@mui/icons-material/RvHookup';

import List from './List';
import Edit from './Edit';

const resource = {
    list: List,
    edit: Edit,
    icon: Icon,
};

export default resource;
