export default [
    {
        id: '15',
        name: 'черновик',
    },
    {
        id: '16',
        name: 'накладная оформлена',
    },
    {
        id: '17',
        name: 'забор запланирован',
    },
    {
        id: '18',
        name: 'груз забран',
    },
    {
        id: '19',
        name: 'на складе отправления',
    },
    {
        id: '20',
        name: 'в пути',
    },
    {
        id: '21',
        name: 'на складе назначения',
    },
    {
        id: '22',
        name: 'доставка запланирована',
    },
    {
        id: '23',
        name: 'груз доставлен',
    },
    {
        id: '24',
        name: 'груз выдан',
    },
    {
        id: '26',
        name: 'выдано на доставку',
    },
];
