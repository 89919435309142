import * as React from 'react';
import {
    Edit,
    TextInput,
    SimpleForm,
    SelectInput,
    required,
} from 'react-admin';
import { Grid } from '@mui/material';

import Aside from './Aside';
import FullNameField from './FullNameField';

const req = [required()];

const EntityEdit = () => {
    return (
        <Edit
            title={<EditTitle />}
            resource="logix_users"
            aside={<Aside />}
            redirect={false}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <div>
                    <Grid
                        container
                        width={{ xs: '100%', xl: '80%' }}
                        spacing={2}
                    >
                        <Grid item xs={12} md={10} xl={12}>
                            <TextInput
                                source="user_id"
                                validate={req}
                                isRequired
                                fullWidth
                            />
                            <TextInput source="access_client_id" fullWidth />
                            <TextInput source="user_hash" fullWidth />
                            <TextInput source="email" fullWidth />
                            <TextInput source="phone" fullWidth />

                            <SelectInput
                                source="section_finances_active"
                                fullWidth
                                choices={[
                                    {
                                        id: 'disabled',
                                        name:
                                            'resources.logix_users.fields.disabled',
                                    },
                                    {
                                        id: 'enabled',
                                        name:
                                            'resources.logix_users.fields.enabled',
                                    },
                                ]}
                            />
                            <SelectInput
                                source="section_claims_active"
                                fullWidth
                                choices={[
                                    {
                                        id: 'disabled',
                                        name:
                                            'resources.logix_users.fields.disabled',
                                    },
                                    {
                                        id: 'enabled',
                                        name:
                                            'resources.logix_users.fields.enabled',
                                    },
                                ]}
                            />
                            {/*
                            TODO Load values, enable input
                            <SelectInput
                                source="default_order_filter_set"
                                fullWidth
                                choices={[]}
                            />
                            */}
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

// @ts-ignore
const EditTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityEdit;
