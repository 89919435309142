import * as React from 'react';
import { Create, SimpleForm, BooleanInput, required } from 'react-admin';
import { RichTextInput } from '../../components/RichTextInput';

const req = [required()];

const EntityCreate = () => {
    return (
        <>
            {/* @ts-ignore */}
            <Create>
                <SimpleForm sx={{ maxWidth: { xl: '80%' } }}>
                    <BooleanInput source="active" fullWidth />
                    <RichTextInput
                        source="question"
                        isRequired
                        validate={req}
                        fullWidth
                    />
                    <RichTextInput source="answer" fullWidth />
                </SimpleForm>
            </Create>
        </>
    );
};

export default EntityCreate;
