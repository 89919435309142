import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { DataProvider } from 'ra-core';

const getCookie = (name: string): string | null => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    }

    return null;
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

const filterImageInParams = async (params: any) => {
    if (params.data?.image?.rawFile instanceof File) {
        const base64Image = await convertFileToBase64(
            params.data.image.rawFile
        );
        params.data.image = {
            src: base64Image,
            title: params.data.image.title,
            type: params.data.image.rawFile.type,
            size: params.data.image.rawFile.size,
        };
    }

    return params;
};

const buildRequest = (
    method: 'get' | 'post' | 'put' | 'delete',
    endpoint: string,
    params?: any,
    data?: any
): Promise<AxiosResponse<any, any>> => {
    const base_url: string =
        (process.env.NODE_ENV === 'production'
            ? import.meta.env.VITE_BACKEND_BASE_URL_PROD
            : import.meta.env.VITE_BACKEND_BASE_URL_DEV) ?? '/';

    if (params && 'filter' in params) {
        let filter_param = params.filter;
        if (typeof filter_param === 'string') {
            filter_param = JSON.parse(filter_param);
        }
        for (let key in filter_param) {
            const value = filter_param[key];
            if (Array.isArray(value)) {
                filter_param[key] = value.join(',');
            }
        }

        params = {
            ...params,
            ...filter_param,
        };
        params['filter'] = undefined;
    }
    const request_config: AxiosRequestConfig = {
        method,
        url: base_url + endpoint + '/',
        params,
        data,
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-csrftoken': getCookie('csrftoken'),
            // 'X-Api-Token': import.meta.env.VITE_BACKEND_API_TOKEN ?? '',
        },
        validateStatus: status => {
            return status >= 200 && status < 300;
        },
    };

    return axios(request_config).catch((error: AxiosError) => {
        const status = error.response?.status;

        if (!(status === 401 || status === 403)) {
            // if status not 401 or 403 extract message to
            // pass it to components
            // else error will be automatically processed
            // by checkError from AuthProvider
            throw new Error(extractErrorMessage(error));
        }

        throw error;
    });
};

const logError = (error: any) => {
    console.error(error);
};

export const extractErrorMessage = (error: any) => {
    logError(error);

    const error_message =
        typeof error?.response?.data === 'string'
            ? error.response.data
            : error?.response?.data?.errors
                  .map((error_item: any) => error_item.detail)
                  ?.join(',');

    return error_message || error.toString() || 'Network error';
};

type ListResponse = {
    data: any;
    total?: number;
};

type EntityResponse = {
    data: any;
};

/**
 * Maps react-admin queries to a REST API
 *
 * @example
 *
 * getList     => GET <backend_url>/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET <backend_url>/posts/123
 * getMany     => GET <backend_url>/posts?filter={id:[123,456,789]}
 * update      => PUT <backend_url>/posts/123
 * create      => POST <backend_url>/posts
 * delete      => DELETE <backend_url>/posts/123
 */
const restDataProvider: DataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const offset = (page - 1) * perPage;

        const query = {
            ordering: `${order === 'ASC' ? '-' : ''}${field}`,
            offset: offset,
            page: page,
            limit: perPage,
            filter: params.filter,
            search: params.filter.q,
            ...params.meta,
        };
        const endpoint = `${resource}`;

        return buildRequest('get', endpoint, query, null).then(
            (response: AxiosResponse) => {
                const listResponse: ListResponse = {
                    data: response.data.results,
                    total: response.data.count,
                };

                return listResponse;
            }
        );
    },
    getOne: (resource, params) => {
        const endpoint = `${resource}/${params.id}`;

        return buildRequest('get', endpoint, params?.meta, null).then(
            ({ data }: AxiosResponse) => {
                const entityResponse: EntityResponse = { data };
                entityResponse.data.id = params.id;

                return entityResponse;
            }
        );
    },

    getMany: (resource, params) => {
        const query = {
            filter: {
                id: params.ids.join(','),
            },
            limit: 10000,
        };
        const endpoint = `${resource}`;

        return buildRequest('get', endpoint, query, null).then(
            (response: AxiosResponse) => {
                const listResponse: ListResponse = {
                    data: response.data.results,
                    total: response.data.count,
                };

                return listResponse;
            }
        );
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const offset = (page - 1) * perPage;

        const query = {
            sort: [field, order],
            offset: offset,
            filter: params.filter,
        };
        const endpoint = `${resource}`;

        return buildRequest('get', endpoint, query, null).then(
            ({ data }: AxiosResponse) => {
                const listResponse: ListResponse = data;

                return listResponse;
            }
        );
    },

    update: async (resource, params: any) => {
        const endpoint = `${resource}/${params.id}`;

        params = await filterImageInParams(params);

        return buildRequest(
            params.data?.method ?? 'put',
            endpoint,
            params?.meta,
            params.data
        ).then(({ data }: AxiosResponse) => {
            const entityResponse: EntityResponse = { data };
            entityResponse.data.id = params.id;

            return entityResponse;
        });
    },

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) => {
        return Promise.all(
            params.ids.map(id => {
                const endpoint = `${resource}/${id}`;

                return buildRequest('put', endpoint, null, params.data);
            })
        ).then(responses => ({
            data: responses.map(({ data }) => {
                const entityResponse: EntityResponse = { data };

                return entityResponse.data.id;
            }),
        }));
    },

    create: async (resource, params) => {
        const endpoint = `${resource}`;

        params = await filterImageInParams(params);

        return buildRequest('post', endpoint, params?.meta, params.data).then(
            ({ data }: AxiosResponse) => {
                const entityResponse: EntityResponse = { data };

                return entityResponse;
            }
        );
    },

    delete: (resource, params) => {
        const endpoint = `${resource}/${params.id}`;

        return buildRequest('delete', endpoint, params?.meta, null).then(
            ({ data }: AxiosResponse) => {
                const entityResponse: EntityResponse = { data };

                return entityResponse;
            }
        );
    },

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) => {
        return Promise.all(
            params.ids.map(id => {
                const endpoint = `${resource}/${id}`;

                return buildRequest('delete', endpoint, params?.meta, null);
            })
        ).then(responses => ({
            data: responses.map(({ data }) => {
                const entityResponse: EntityResponse = data;

                return entityResponse?.data?.id;
            }),
        }));
    },
};

export default restDataProvider;
