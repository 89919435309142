import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { AuthProvider, UserIdentity } from 'react-admin';

type AuthLoginResponse = {
    user_id: number;
    user_name: string;
    user_avatar: string | null;
    errors: string | undefined;
};

const getCookie = (name: string): string | null => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    }

    return null;
};

const getRequest = (
    endpoint: string,
    data?: any
): Promise<AxiosResponse<any, any>> => {
    const base_url: string =
        (process.env.NODE_ENV === 'production'
            ? import.meta.env.VITE_BACKEND_BASE_URL_PROD
            : import.meta.env.VITE_BACKEND_BASE_URL_DEV) ?? '/';
    const request_config: AxiosRequestConfig = {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-csrftoken': getCookie('csrftoken'),
            // 'X-Api-Token': import.meta.env.VITE_BACKEND_API_TOKEN ?? '',
        },
        validateStatus: status => status === 200,
    };

    return axios.post(base_url + endpoint + '/', data, request_config);
};

const clearLoginData = () => {
    localStorage.removeItem('current_user');
};

const logError = (error: any) => {
    console.error(error);
};

const extractErrorMessage = (error: any) => {
    logError(error);

    const error_message =
        typeof error?.response?.data === 'string'
            ? error.response.data
            : error?.response?.data?.errors
                  .map((error_item: any) => error_item.detail)
                  ?.join(',');

    return error_message || error.toString() || 'Network error';
};

const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        const request: Promise<AxiosResponse<
            any,
            any
        >> = getRequest('users/login', { username, password });

        return request
            .then(({ data }: AxiosResponse) => {
                const authResponse: AuthLoginResponse = data;

                const current_user: UserIdentity = {
                    id: authResponse.user_id,
                    fullName: authResponse.user_name,
                    avatar: authResponse.user_avatar ?? undefined,
                };
                localStorage.setItem(
                    'current_user',
                    JSON.stringify(current_user)
                );
            })
            .catch(error => {
                throw new Error(extractErrorMessage(error));
            });
    },
    logout: () => {
        const request: Promise<AxiosResponse<any, any>> = getRequest(
            'users/logout'
        );

        return request
            .then((_: AxiosResponse) => {
                clearLoginData();
                return Promise.resolve();
            })
            .catch(error => {
                if (error?.response?.status === 403) {
                    return Promise.resolve();
                }
                throw new Error(extractErrorMessage(error));
            });
    },
    checkError: (error: AxiosError) => {
        // Check response error to automatically logout
        // in case of 401 error code

        const status = error.response?.status;
        if (status === 401 || status === 403) {
            clearLoginData();
            return Promise.reject();
        }

        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => {
        // Check if current_user value present in storage
        return localStorage.getItem('current_user')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => {
        // No need to check permission in auth provider
        // This will be handled in data provider
        return Promise.resolve();
    },
    getIdentity: () => {
        const saved_user: string | null = localStorage.getItem('current_user');
        const current_user: UserIdentity = saved_user
            ? JSON.parse(saved_user)
            : null;

        return Promise.resolve(current_user);
    },
};

export default authProvider;
