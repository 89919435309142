import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SearchInput,
    TextInput,
    SelectInput,
    BooleanField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import MobileGrid from './MobileGrid';
import Show from './Show';

const entityFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="geo_id" />,
    <TextInput source="title" />,
    <TextInput source="fias_id" />,
    <TextInput source="active" />,
    <SelectInput
        source="type"
        choices={[
            { id: 'locality', name: 'НП' },
            { id: 'country', name: 'Страна' },
            { id: 'region', name: 'Регион' },
            { id: 'disctrict', name: 'Район' },
        ]}
        alwaysOn
    />,
];

const EntityList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    // const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        // @ts-ignore
        <List
            filters={entityFilters}
            resource="logix_cities"
            sort={{ field: 'geo_id', order: 'DESC' }}
            perPage={25}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    expand={<Show />}
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField source="geo_id" />
                    <TextField source="type" />
                    <TextField source="title_selector" />
                    <TextField source="fias_id" />
                    <BooleanField source="active" />
                </Datagrid>
            )}
        </List>
    );
};

export default EntityList;
