import * as React from 'react';
import {
    List,
    Datagrid,
    NumberField,
    CreateButton,
    TopToolbar,
    Identifier,
    BulkDeleteButton,
    useTranslate,
} from 'react-admin';

const ListActions = () => {
    return (
        <TopToolbar>
            {/* @ts-ignore */}
            <CreateButton resource="settings/calculation_insurance_prices" />
        </TopToolbar>
    );
};

const ListBulkActionButtons = () => (
    <>
        <BulkDeleteButton
            mutationOptions={{
                meta: {
                    namespace: 'Logistics',
                    path: 'calculation_insurance_prices',
                },
            }}
            mutationMode="pessimistic"
        />
    </>
);

const rowClick = (id: Identifier) => {
    return `/settings/calculation_insurance_prices/${id}`;
};

const EntityList = () => {
    const translate = useTranslate();

    return (
        // @ts-ignore
        <List
            actions={<ListActions />}
            pagination={false}
            exporter={false}
            resource="settings/serializable"
            queryOptions={{
                meta: {
                    namespace: 'Logistics',
                    path: 'calculation_insurance_prices',
                },
            }}
        >
            <Datagrid
                optimized
                rowClick={rowClick}
                bulkActionButtons={<ListBulkActionButtons />}
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <NumberField
                    source="limit"
                    label={translate(
                        'resources.calculation_insurance_prices.fields.limit'
                    )}
                />
                <NumberField
                    source="price"
                    label={translate(
                        'resources.calculation_insurance_prices.fields.price'
                    )}
                />
            </Datagrid>
        </List>
    );
};

export default EntityList;
