import * as React from 'react';
import { TabbedForm, useTranslate, useNotify, useRedirect } from 'react-admin';
import { Grid, Box, Button } from '@mui/material';
import dataProvider from '../../dataProvider/restProvider';

const LogixEdit = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const executeAction = (action: string) => {
        return () =>
            dataProvider
                .create('actions', {
                    data: {
                        action: action,
                    },
                })
                .then(data => {
                    if (data?.data?.id) {
                        redirect(`/tasks/${data.data.id}/show/`);
                    }
                })
                .catch(err => {
                    notify(err ? `${err}` : 'resources.actions.errors.common', {
                        type: 'error',
                    });
                });
    };

    return (
        <TabbedForm toolbar={false}>
            <TabbedForm.Tab label="resources.actions.fieldGroups.logix">
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        minWidth={{ xs: '100%', xl: '80%' }}
                    >
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction(
                                    'logix_dictionaries_sync'
                                )}
                            >
                                {translate(
                                    'resources.actions.page.logix_start_sync'
                                )}
                            </Button>
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction('logix_geo_sync')}
                            >
                                {translate(
                                    'resources.actions.page.logix_start_sync_geo'
                                )}
                            </Button>
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction('logix_geo_map_sync')}
                            >
                                {translate(
                                    'resources.actions.page.logix_start_sync_geo_map'
                                )}
                            </Button>
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction('logix_prices_sync')}
                            >
                                {translate(
                                    'resources.actions.page.logix_start_sync_prices'
                                )}
                            </Button>
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction(
                                    'logix_cargo_types_sync'
                                )}
                            >
                                {translate(
                                    'resources.actions.page.logix_start_sync_cargo_types'
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="resources.actions.fieldGroups.dynamic_pages">
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        minWidth={{ xs: '100%', xl: '80%' }}
                    >
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction('regenerate_city_pages')}
                            >
                                {translate(
                                    'resources.actions.page.dynamic_pages_cities'
                                )}
                            </Button>
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction(
                                    'regenerate_delivery_pages'
                                )}
                            >
                                {translate(
                                    'resources.actions.page.dynamic_pages_delivery'
                                )}
                            </Button>
                        </Box>
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction(
                                    'check_city_items_pages'
                                )}
                            >
                                {translate(
                                    'resources.actions.page.dynamic_pages_cities_items'
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="resources.actions.fieldGroups.common">
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        minWidth={{ xs: '100%', xl: '80%' }}
                    >
                        <Box marginBottom={2}>
                            <Button
                                variant="contained"
                                onClick={executeAction('clear_cache')}
                            >
                                {translate(
                                    'resources.actions.page.clear_cache'
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </TabbedForm.Tab>
        </TabbedForm>
    );
};

export default LogixEdit;
