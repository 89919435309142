import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    CreateButton,
    TopToolbar,
    Identifier,
    BulkDeleteButton,
    useTranslate,
    FunctionField,
    RaRecord,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import default_sets from './default_sets';

const ListActions = () => {
    return (
        <TopToolbar>
            {/* @ts-ignore */}
            <CreateButton resource="settings/orders_filter_status_sets" />
        </TopToolbar>
    );
};

const ListEmpty = () => {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="body1"> Нет значений </Typography>
            {/* @ts-ignore */}
            <CreateButton resource="settings/orders_filter_status_sets" />
        </Box>
    );
};

const ListBulkActionButtons = () => (
    <>
        <BulkDeleteButton
            mutationOptions={{
                meta: {
                    namespace: 'ApiLogix',
                    path: 'orders_filter_status_sets',
                },
            }}
            mutationMode="pessimistic"
        />
    </>
);

const rowClick = (id: Identifier) => {
    return `/settings/orders_filter_status_sets/${id}`;
};

const EntityList = () => {
    const translate = useTranslate();

    const setFieldValue = (record: RaRecord) => {
        return record.set
            .map(
                (value: string) =>
                    default_sets.find(set => set.id === value)?.name
            )
            .filter((value: string | undefined) => !!value)
            .join(', ');
    };

    return (
        // @ts-ignore
        <List
            actions={<ListActions />}
            empty={<ListEmpty />}
            pagination={false}
            exporter={false}
            resource="settings/serializable"
            queryOptions={{
                meta: {
                    namespace: 'ApiLogix',
                    path: 'orders_filter_status_sets',
                },
            }}
        >
            <Datagrid
                optimized
                rowClick={rowClick}
                bulkActionButtons={<ListBulkActionButtons />}
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField
                    source="label"
                    label={translate(
                        'resources.orders_filter_status_sets.fields.label'
                    )}
                />
                <FunctionField
                    source="set"
                    label={translate(
                        'resources.orders_filter_status_sets.fields.set'
                    )}
                    render={(record: RaRecord) => setFieldValue(record)}
                />
                <BooleanField
                    source="front"
                    label={translate(
                        'resources.orders_filter_status_sets.fields.front'
                    )}
                />
            </Datagrid>
        </List>
    );
};

export default EntityList;
