import * as React from 'react';
import {
    DateField,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField,
    useTranslate,
    useRefresh,
    ArrayField,
    Datagrid,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { TaskStatusChoices } from './TaskParams';

const EntityShow = () => {
    const translate = useTranslate();
    const refresh = useRefresh();

    React.useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, 2500);

        return () => clearInterval(interval);
    });

    return (
        // @ts-ignore
        <Show title={<ShowTitle />} resource="tasks" aside={<Aside />}>
            <SimpleShowLayout>
                <div>
                    <Grid
                        container
                        width={{ xs: '100%', xl: '80%' }}
                        spacing={2}
                    >
                        <Grid item xs={12} md={10} xl={12}>
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.tasks.fields.hook')}
                            </Typography>
                            <TextField source="hook" />
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.tasks.fields.parsed_args'
                                )}
                            </Typography>
                            <TextField source="args" emptyText="N/A" />
                            <TextField source="author" />
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.tasks.fields.status')}
                            </Typography>
                            <SelectField
                                source="status"
                                choices={TaskStatusChoices(translate)}
                            />
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.tasks.fields.execution_started_time'
                                )}
                            </Typography>
                            <DateField
                                showTime={true}
                                source="execution_started_time"
                                emptyText="N/A"
                            />
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.tasks.fields.execution_ended_time'
                                )}
                            </Typography>
                            <DateField
                                showTime={true}
                                source="execution_ended_time"
                                emptyText="N/A"
                            />
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.tasks.fields.log')}
                            </Typography>
                            <ArrayField
                                source="parsed_log_reverse"
                                perPage={50}
                            >
                                <Datagrid isRowSelectable={_ => false}>
                                    <DateField source="date" showTime />
                                    <TextField source="record" />
                                </Datagrid>
                            </ArrayField>
                        </Grid>
                    </Grid>
                </div>
            </SimpleShowLayout>
        </Show>
    );
};

// @ts-ignore
const ShowTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityShow;
