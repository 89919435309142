import Icon from '@mui/icons-material/People';

import List from './List';
// It's better to not allow to manually create logix users
// import Create from './Create';
import Edit from './Edit';

const resource = {
    list: List,
    // create: Create,
    edit: Edit,
    icon: Icon,
};

export default resource;
