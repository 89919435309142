import * as React from 'react';
import {
    Edit,
    BooleanInput,
    TextInput,
    SimpleForm,
    useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { RichTextInput } from '../../components/RichTextInput';

import Aside from './Aside';
import FullNameField from './FullNameField';

const EntityEdit = () => {
    const translate = useTranslate();

    return (
        <Edit
            title={<EditTitle />}
            aside={<Aside />}
            redirect={false}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <div>
                    <Grid
                        container
                        width={{ xs: '100%', xl: '80%' }}
                        spacing={2}
                    >
                        <Grid item xs={12} md={10} xl={12}>
                            <TextInput source="title" fullWidth />
                            <BooleanInput source="active" fullWidth />
                            <TextInput
                                source="annotation"
                                fullWidth
                                multiline
                                maxRows={'10'}
                            />
                            <RichTextInput source="top_content" fullWidth />
                            <RichTextInput source="content" fullWidth />
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.city_pages.fieldGroups.additional'
                                )}
                            </Typography>
                            <TextInput
                                source="links_title_city_from"
                                fullWidth
                            />
                            <TextInput source="links_title_city_to" fullWidth />
                            <TextInput
                                source="links_title_intercity_from"
                                fullWidth
                            />
                            <TextInput
                                source="links_title_intercity_to"
                                fullWidth
                            />
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.city_pages.fieldGroups.seo'
                                )}
                            </Typography>
                            <TextInput source="seo_h1" fullWidth />
                            <TextInput
                                source="seo_description"
                                fullWidth
                                multiline
                                maxRows={'10'}
                            />
                            <BooleanInput source="seo_no_index" fullWidth />
                            <TextInput source="seo_canonical" fullWidth />
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

// @ts-ignore
const EditTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityEdit;
