import Icon from '@mui/icons-material/PendingActions';

import List from './List';
import Create from './Create';
import Edit from './Edit';

const resource = {
    list: List,
    create: Create,
    edit: Edit,
    icon: Icon,
};

export default resource;
