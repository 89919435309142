import * as React from 'react';
import {
    Create,
    ImageInput,
    ImageField,
    TextInput,
    SimpleForm,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useTranslate,
} from 'react-admin';
import { RichTextInput } from '../../components/RichTextInput';
import { Typography } from '@mui/material';
import { DatePickerInput } from '../../components/DatePickerInput';

const req = [required()];

const EntityCreate = () => {
    const translate = useTranslate();

    return (
        <>
            {/* @ts-ignore */}
            <Create>
                <SimpleForm sx={{ maxWidth: { xl: '80%' } }}>
                    <TextInput
                        source="title"
                        validate={req}
                        isRequired
                        fullWidth
                    />
                    <TextInput
                        source="slug"
                        validate={req}
                        isRequired
                        fullWidth
                    />
                    <BooleanInput source="active" fullWidth />
                    <ImageInput source="image" fullWidth defaultValue={null}>
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <TextInput
                        source="annotation"
                        fullWidth
                        multiline
                        maxRows={'10'}
                    />
                    <RichTextInput source="content" fullWidth />
                    <DatePickerInput
                        source="content_date"
                        label={translate('resources.news.fields.content_date')}
                    />
                    <DatePickerInput
                        source="active_time"
                        label={translate('resources.news.fields.active_time')}
                    />
                    <ReferenceInput source="city_id" reference="cities/cities">
                        <AutocompleteInput optionText="title" fullWidth />
                    </ReferenceInput>
                    <BooleanInput
                        source="is_all_cities"
                        fullWidth
                        defaultValue={true}
                    />
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.pages.fieldGroups.seo')}
                    </Typography>
                    <TextInput source="seo_h1" fullWidth />
                    <TextInput
                        source="seo_description"
                        fullWidth
                        multiline
                        maxRows={'10'}
                    />
                    <BooleanInput source="seo_no_index" fullWidth />
                    <TextInput source="seo_canonical" fullWidth />
                </SimpleForm>
            </Create>
        </>
    );
};

export default EntityCreate;
