import * as React from 'react';
import {
    Edit,
    BooleanInput,
    NumberInput,
    TextInput,
    SimpleForm,
    required,
} from 'react-admin';
import { Grid } from '@mui/material';

import Aside from './Aside';
import FullNameField from './FullNameField';

const req = [required()];

const EntityEdit = () => {
    return (
        <Edit
            title={<EditTitle />}
            aside={<Aside />}
            redirect={false}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <div>
                    <Grid
                        container
                        width={{ xs: '100%', xl: '80%' }}
                        spacing={2}
                    >
                        <Grid item xs={12} md={10} xl={12}>
                            <TextInput
                                source="title"
                                isRequired
                                validate={req}
                                fullWidth
                            />
                            <NumberInput source="price" fullWidth />
                            <NumberInput source="minimum" fullWidth />
                            <TextInput
                                source="calc_note"
                                multiline
                                maxRows={10}
                                fullWidth
                            />
                            <BooleanInput source="countable" fullWidth />
                            <TextInput source="countable_label" fullWidth />
                            <NumberInput source="multiplier_1" fullWidth />
                            <NumberInput source="multiplier_2" fullWidth />
                            <TextInput source="units_label" fullWidth />
                            <TextInput
                                source="comment"
                                multiline
                                maxRows={10}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

// @ts-ignore
const EditTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityEdit;
