import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import ContentIcon from '@mui/icons-material/Source';
import ToolsIcon from '@mui/icons-material/Handyman';
import BlocksIcon from '@mui/icons-material/Widgets';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import admins from '../admins';
import settings from '../settings';
import cities from '../cities';
import blocks_packings from '../blocks/packings';
import blocks_advantages from '../blocks/advantages';
import blocks_slides from '../blocks/slides';
import blocks_faqs from '../blocks/faqs';
import blocks_services from '../blocks/services';
import pages from '../content/pages';
import news from '../content/news';
import sales from '../content/sales';
import articles from '../content/articles';
import vacancies from '../content/vacancies';
import city_pages from '../content/city_pages';
import city_items_pages from '../content/city_items_pages';
import delivery_pages from '../content/delivery_pages';
import tasks from '../tools/tasks';
import actions from '../tools/actions';
import feedbacks from '../feedbacks';
import logix_users from '../logix_users';
import logix_offices from '../logix_offices';
import logix_storehouses from '../logix_storehouses';
import logix_client_metas from '../logix_client_metas';
import logix_cities from '../logix_cities';
import robots from '../seo/robots';
import SubMenu from './SubMenu';

type MenuName =
    | 'menuContent'
    | 'menuTools'
    | 'menuSettings'
    | 'menuCities'
    | 'menuSeo'
    | 'menuLogix'
    | 'menuBlocks';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuContent: true,
        menuCities: true,
        menuTools: true,
        menuSettings: true,
        menuBlocks: true,
        menuSeo: true,
        menuLogix: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 300 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('menuContent')}
                isOpen={state.menuContent}
                name="pos.menu.content"
                icon={<ContentIcon />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/pages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.pages.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<pages.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/news"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.news.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<news.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/sales"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.sales.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<sales.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/articles"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.articles.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<articles.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/vacancies"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.vacancies.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<vacancies.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/city_pages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.city_pages.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<city_pages.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/city_items_pages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.city_items_pages.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<city_items_pages.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/delivery_pages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.delivery_pages.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<delivery_pages.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuBlocks')}
                isOpen={state.menuBlocks}
                name="pos.menu.blocks"
                icon={<BlocksIcon />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/blocks/packings"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.blocks/packings.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<blocks_packings.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/blocks/advantages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.blocks/advantages.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<blocks_advantages.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/blocks/slides"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.blocks/slides.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<blocks_slides.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/blocks/faqs"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.blocks/faqs.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<blocks_faqs.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/blocks/services"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.blocks/services.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<blocks_services.icon />}
                    dense={dense}
                />
            </SubMenu>
            {/* @ts-ignore */}
            <MenuItemLink
                to="/cities"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.cities.name`, {
                    smart_count: 2,
                })}
                leftIcon={<cities.icon />}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuSeo')}
                isOpen={state.menuSeo}
                name="pos.menu.seo"
                icon={<ContentIcon />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/robots"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.robots.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<robots.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuLogix')}
                isOpen={state.menuLogix}
                name="pos.menu.logix"
                icon={<ContentIcon />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/logix_cities"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.logix_cities.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<logix_cities.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/logix_users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.logix_users.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<logix_users.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/logix_offices"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.logix_offices.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<logix_offices.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/logix_storehouses"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.logix_storehouses.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<logix_storehouses.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/logix_client_metas"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(
                        `resources.logix_client_metas.name`,
                        {
                            smart_count: 2,
                        }
                    )}
                    leftIcon={<logix_client_metas.icon />}
                    dense={dense}
                />
            </SubMenu>
            {/* @ts-ignore */}
            <MenuItemLink
                to="/feedbacks"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.feedbacks.name`, {
                    smart_count: 2,
                })}
                leftIcon={<feedbacks.icon />}
                dense={dense}
            />
            {/* @ts-ignore */}
            <MenuItemLink
                to="/admins"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.admins.name`, {
                    smart_count: 2,
                })}
                leftIcon={<admins.icon />}
                dense={dense}
            />
            <SubMenu
                handleToggle={() => handleToggle('menuTools')}
                isOpen={state.menuTools}
                name="pos.menu.tools"
                icon={<ToolsIcon />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/tasks"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.tasks.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<tasks.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/actions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.actions.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<actions.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuSettings')}
                isOpen={state.menuSettings}
                name="pos.menu.settings"
                icon={<settings.icon />}
                dense={dense}
            >
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/settings/general"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.settings.general`, {
                        smart_count: 2,
                    })}
                    leftIcon={<settings.general.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/settings/logix"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.settings.logix`, {
                        smart_count: 2,
                    })}
                    leftIcon={<settings.logix.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/settings/calculator"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.settings.calculator`, {
                        smart_count: 2,
                    })}
                    leftIcon={<settings.calculator.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/settings/seo"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.settings.seo`, {
                        smart_count: 2,
                    })}
                    leftIcon={<settings.seo.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/settings/shortcodes"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.settings.shortcodes`, {
                        smart_count: 2,
                    })}
                    leftIcon={<settings.shortcodes.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/settings/cities"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.settings.cities`, {
                        smart_count: 2,
                    })}
                    leftIcon={<settings.cities.icon />}
                    dense={dense}
                />
                {/* @ts-ignore */}
                <MenuItemLink
                    to="/settings/dynamic_pages"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.settings.dynamic_pages`, {
                        smart_count: 2,
                    })}
                    leftIcon={<settings.dynamic_pages.icon />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;
