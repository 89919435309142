import Icon from '@mui/icons-material/Task';

import List from './List';
import Show from './Show';

const resource = {
    list: List,
    show: Show,
    icon: Icon,
};

export default resource;
