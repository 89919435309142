// in LatLongInput.js
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useInput } from 'react-admin';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

export const DatePickerInput = (props: any) => {
    const { field } = useInput(props);
    const momentValue = field.value ? moment(field.value, dateFormat) : null;
    const onDateChange = (value: moment.Moment | null) => {
        const parsedValue = value ? value.format(dateFormat) : null;

        return field.onChange(parsedValue);
    };

    return (
        <DatePicker
            sx={{ width: '100%' }}
            {...field}
            {...props}
            onChange={onDateChange}
            slotProps={{
                field: {
                    clearable: true,
                    required: props?.isRequired,
                },
            }}
            format={dateFormat}
            timezone="Europe/Moscow"
            value={momentValue}
        />
    );
};
