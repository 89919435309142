import Icon from '@mui/icons-material/SpeakerNotes';

import List from './List';

const resource = {
    list: List,
    icon: Icon,
};

export default resource;
