import { Translate } from 'react-admin';

export const TaskStatusChoices = (translate: Translate) => [
    {
        id: '-1',
        name: translate('resources.tasks.fields.status_options.failed'),
    },
    {
        id: '0',
        name: translate('resources.tasks.fields.status_options.created'),
    },
    {
        id: '1',
        name: translate('resources.tasks.fields.status_options.in_progress'),
    },
    {
        id: '2',
        name: translate('resources.tasks.fields.status_options.executed'),
    },
];
