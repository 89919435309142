import * as React from 'react';
import { Create, TextInput, SimpleForm, required } from 'react-admin';

const req = [required()];

const EntityCreate = () => {
    return (
        <>
            {/* @ts-ignore */}
            <Create resource="logix_client_metas">
                <SimpleForm sx={{ maxWidth: { xl: '80%' } }}>
                    <TextInput
                        source="full_name"
                        validate={req}
                        isRequired
                        fullWidth
                    />
                    <TextInput source="leader_position" fullWidth />
                    <TextInput source="leader_name" fullWidth />
                </SimpleForm>
            </Create>
        </>
    );
};

export default EntityCreate;
