import * as React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

import { Page } from '../types';

const Show = () => {
    const record = useRecordContext<Page>();
    if (!record) return null;

    return (
        <Card sx={{ width: 600, margin: 'auto' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            {record.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom align="right">
                            {record.id}
                        </Typography>
                    </Grid>
                </Grid>
                <Box height={20}>&nbsp;</Box>
                <Box margin="10px 0">
                    <Typography variant="body2" gutterBottom>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: record.content,
                            }}
                        ></div>
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Show;
