import SettingIcon from '@mui/icons-material/Settings';
import ApiIcon from '@mui/icons-material/Api';
import CalculateIcon from '@mui/icons-material/Calculate';
import SeoIcon from '@mui/icons-material/SavedSearch';
import TagIcon from '@mui/icons-material/Tag';
import CityIcon from '@mui/icons-material/LocationCity';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

import SettingsList from './SettingsList';
import GeneralForm from './GeneralForm';
import LogixForm from './LogixForm';
import CalculatorForm from './CalculatorForm';
import SeoForm from './SeoForm';
import ShortcodesForm from './ShortcodesForm';
import CitiesForm from './CitiesForm';
import DynamicPagesForm from './DynamicPagesForm';

import calculation_insurance_prices from './calculation_insurance_prices';
import orders_filter_status_sets from './orders_filter_status_sets';

const resource = {
    icon: SettingIcon,
    list: SettingsList,
    general: {
        icon: SettingIcon,
        form: GeneralForm,
    },
    logix: {
        icon: ApiIcon,
        form: LogixForm,
    },
    calculator: {
        icon: CalculateIcon,
        form: CalculatorForm,
    },
    seo: {
        icon: SeoIcon,
        form: SeoForm,
    },
    shortcodes: {
        icon: TagIcon,
        form: ShortcodesForm,
    },
    cities: {
        icon: CityIcon,
        form: CitiesForm,
    },
    dynamic_pages: {
        icon: AutoStoriesIcon,
        form: DynamicPagesForm,
    },
    calculation_insurance_prices,
    orders_filter_status_sets,
};

export default resource;
