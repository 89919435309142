import * as React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    required,
} from 'react-admin';

const req = [required()];

const EntityCreate = () => {
    return (
        <>
            {/* @ts-ignore */}
            <Create>
                <SimpleForm sx={{ maxWidth: { xl: '80%' } }}>
                    <ReferenceInput source="city" reference="cities">
                        <SelectInput optionText="title" fullWidth />
                    </ReferenceInput>
                    <TextInput
                        source="body"
                        fullWidth
                        multiline
                        maxRows={'10'}
                        validate={req}
                    />
                </SimpleForm>
            </Create>
        </>
    );
};

export default EntityCreate;
