import * as React from 'react';
import { Edit, BooleanInput, SimpleForm, required } from 'react-admin';
import { Grid } from '@mui/material';
import { RichTextInput } from '../../components/RichTextInput';

import Aside from './Aside';
import FullNameField from './FullNameField';

const req = [required()];

const EntityEdit = () => {
    return (
        <Edit
            title={<EditTitle />}
            aside={<Aside />}
            redirect={false}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <div>
                    <Grid
                        container
                        width={{ xs: '100%', xl: '80%' }}
                        spacing={2}
                    >
                        <Grid item xs={12} md={10} xl={12}>
                            <BooleanInput source="active" fullWidth />
                            <RichTextInput
                                source="question"
                                isRequired
                                validate={req}
                                fullWidth
                            />
                            <RichTextInput source="answer" fullWidth />
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

// @ts-ignore
const EditTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityEdit;
