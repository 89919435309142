import * as React from 'react';
import {
    Edit,
    TextInput,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    required,
} from 'react-admin';
import { Grid } from '@mui/material';

import Aside from './Aside';
import FullNameField from './FullNameField';

const req = [required()];

const EntityEdit = () => {
    return (
        <Edit
            title={<EditTitle />}
            aside={<Aside />}
            redirect={false}
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid item xs={12} md={10} xl={12}>
                        <ReferenceInput source="city" reference="cities">
                            <SelectInput optionText="title" fullWidth />
                        </ReferenceInput>
                        <TextInput
                            source="body"
                            fullWidth
                            multiline
                            maxRows={'10'}
                            validate={req}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

// @ts-ignore
const EditTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityEdit;
