import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';

import authProvider from './authProvider';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import russianMessages from './i18n/ru';
import { lightTheme, darkTheme } from './layout/themes';

import dataProvider from './dataProvider';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';

import actions from './tools/actions';
import admins from './admins';
import articles from './content/articles';
import blocks_advantages from './blocks/advantages';
import blocks_faqs from './blocks/faqs';
import blocks_packings from './blocks/packings';
import blocks_services from './blocks/services';
import blocks_slides from './blocks/slides';
import cities from './cities';
import city_items_pages from './content/city_items_pages';
import city_pages from './content/city_pages';
import delivery_pages from './content/delivery_pages';
import feedbacks from './feedbacks';
import logix_users from './logix_users';
import logix_offices from './logix_offices';
import logix_storehouses from './logix_storehouses';
import logix_client_metas from './logix_client_metas';
import logix_cities from './logix_cities';
import news from './content/news';
import pages from './content/pages';
import robots from './seo/robots/';
import sales from './content/sales';
import settings from './settings';
import tasks from './tools/tasks';
import vacancies from './content/vacancies';

const i18nProvider = polyglotI18nProvider(
    locale => {
        if (locale === 'en') {
            return import('./i18n/en').then(messages => messages.default);
        }

        // Always fallback on russian
        return russianMessages;
    },
    'ru',
    [
        { locale: 'ru', name: 'Русский' },
        { locale: 'en', name: 'English' },
    ]
);

const App = () => (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
        <Admin
            title=""
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
            darkTheme={darkTheme}
            defaultTheme="light"
        >
            <Resource name="admins" {...admins} />
            <Resource name="settings" {...settings}>
                {/* @ts-ignore */}
                <Route path="/general" element={<settings.general.form />} />
                {/* @ts-ignore */}
                <Route path="/logix" element={<settings.logix.form />} />
                {/* @ts-ignore */}
                <Route
                    path="/calculator"
                    element={<settings.calculator.form />}
                />
                {/* @ts-ignore */}
                <Route path="/seo" element={<settings.seo.form />} />
                {/* @ts-ignore */}
                <Route
                    path="/shortcodes"
                    element={<settings.shortcodes.form />}
                />
                {/* @ts-ignore */}
                <Route path="/cities" element={<settings.cities.form />} />
                {/* @ts-ignore */}
                <Route
                    path="/dynamic_pages"
                    element={<settings.dynamic_pages.form />}
                />
            </Resource>
            <Resource
                name="settings/calculation_insurance_prices"
                {...settings.calculation_insurance_prices}
            />
            <Resource
                name="settings/orders_filter_status_sets"
                {...settings.orders_filter_status_sets}
            />
            <Resource name="cities" {...cities} />
            <Resource name="blocks/packings" {...blocks_packings} />
            <Resource name="blocks/advantages" {...blocks_advantages} />
            <Resource name="blocks/slides" {...blocks_slides} />
            <Resource name="blocks/faqs" {...blocks_faqs} />
            <Resource name="blocks/services" {...blocks_services} />
            <Resource name="pages" {...pages} />
            <Resource name="news" {...news} />
            <Resource name="sales" {...sales} />
            <Resource name="articles" {...articles} />
            <Resource name="vacancies" {...vacancies} />
            <Resource name="city_pages" {...city_pages} />
            <Resource name="city_items_pages" {...city_items_pages} />
            <Resource name="delivery_pages" {...delivery_pages} />
            <Resource name="tasks" {...tasks} />
            <Resource name="actions" {...actions} />
            <Resource name="feedbacks" {...feedbacks} />
            <Resource name="logix_users" {...logix_users} />
            <Resource name="logix_offices" {...logix_offices} />
            <Resource name="logix_storehouses" {...logix_storehouses} />
            <Resource name="logix_client_metas" {...logix_client_metas} />
            <Resource name="logix_cities" {...logix_cities} />
            <Resource name="robots" {...robots} />
        </Admin>
    </LocalizationProvider>
);

export default App;
