import * as React from 'react';
import {
    Edit,
    TextInput,
    BooleanInput,
    SimpleForm,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    useTranslate,
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import SegmentsFormToolbar from './SegmentsFormToolbar';
import OrderFilterStatusSets from './orders_filter_status_sets';

const LogixEdit = () => {
    const translate = useTranslate();
    const formDefaults = {
        multiple: true,
        method: 'post',
    };
    const OrderFilterStatusList = OrderFilterStatusSets.list;

    return (
        <Edit resource="settings" redirect={false} mutationMode="pessimistic">
            <SimpleForm
                defaultValues={formDefaults}
                toolbar={<SegmentsFormToolbar />}
            >
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        minWidth={{ xs: '100%', xl: '80%' }}
                    >
                        <Typography variant="h4" gutterBottom>
                            {translate('resources.settings.fieldGroups.logix')}
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.logix_basic'
                            )}
                        </Typography>
                        <Box>
                            <TextInput
                                source="ApiLogix__server"
                                label="resources.settings.values.api_logix__server"
                                fullWidth
                            />
                            <BooleanInput
                                source="ApiLogix__debug_front"
                                label="resources.settings.values.api_logix__debug_front"
                                fullWidth
                            />
                        </Box>
                        <Box>
                            <BooleanInput
                                source="ApiLogix__debug_storehouses"
                                label="resources.settings.values.api_logix__debug_storehouses"
                                fullWidth
                            />
                            <BooleanInput
                                source="ApiLogix__force_actions"
                                label="resources.settings.values.api_logix__force_actions"
                                fullWidth
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.logix_sync_user'
                            )}
                        </Typography>
                        <Box>
                            <TextInput
                                source="ApiLogix__sync_user_id"
                                label="resources.settings.values.api_logix__sync_user_id"
                                fullWidth
                            />
                            <TextInput
                                source="ApiLogix__sync_user_hash"
                                label="resources.settings.values.api_logix__sync_user_hash"
                                fullWidth
                            />
                        </Box>
                        <Typography variant="h5" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.logix_additional'
                            )}
                        </Typography>
                        <Box>
                            <NumberInput
                                source="ApiLogix__prices_derivation_percent"
                                label="resources.settings.values.api_logix__prices_derivation_percent"
                                min={0}
                                max={100}
                                step={1}
                                defaultValue={10}
                                fullWidth
                            />
                        </Box>
                        <Typography variant="h6" gutterBottom marginLeft={2}>
                            {translate(
                                'resources.settings.values.api_logix__orders_filter_status_sets'
                            )}
                        </Typography>
                        <Box>
                            <ReferenceInput
                                source="ApiLogix__orders_filter_status_sets_default"
                                reference="settings/serializable"
                                queryOptions={{
                                    meta: {
                                        namespace: 'ApiLogix',
                                        path: 'orders_filter_status_sets',
                                    },
                                }}
                            >
                                <AutocompleteInput
                                    label="resources.settings.values.api_logix__orders_filter_status_sets_default"
                                    optionText="label"
                                    fullWidth
                                />
                            </ReferenceInput>
                        </Box>
                        <OrderFilterStatusList />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default LogixEdit;
