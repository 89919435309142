import * as React from 'react';
import {
    Edit,
    BooleanInput,
    TextInput,
    SimpleForm,
    ReferenceInput,
    NumberInput,
    required,
    AutocompleteInput,
    useTranslate,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';

import Aside from './Aside';
import FullNameField from './FullNameField';

const req = [required()];

const EntityEdit = () => {
    const translate = useTranslate();

    return (
        <Edit
            title={<EditTitle />}
            aside={<Aside />}
            redirect="show"
            mutationMode="pessimistic"
        >
            <SimpleForm>
                <div>
                    <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextInput
                                source="title"
                                validate={req}
                                isRequired
                                fullWidth
                            />
                            <TextInput source="domain" fullWidth />
                            <TextInput source="slug" fullWidth />
                            <BooleanInput
                                source="active"
                                fullWidth
                                helperText={translate(
                                    'resources.cities.helpers.active'
                                )}
                            />
                            <TextInput
                                type="email"
                                source="email"
                                validate={req}
                                isRequired
                                fullWidth
                            />
                            <NumberInput source="min_price" fullWidth />
                            <TextInput source="delivery_time" fullWidth />
                            <TextInput source="paid_entry" fullWidth />
                            <TextInput source="terminal_name" fullWidth />
                            <TextInput
                                source="wordform_vn"
                                fullWidth
                                validate={req}
                                isRequired
                            />
                            <TextInput
                                source="wordform_rod"
                                fullWidth
                                validate={req}
                                isRequired
                            />
                            <TextInput
                                source="wordform_dat"
                                fullWidth
                                validate={req}
                                isRequired
                            />
                            <TextInput
                                source="wordform_pr"
                                fullWidth
                                validate={req}
                                isRequired
                            />
                            {/* <TextInput source="additional" fullWidth /> */}
                            <TextInput source="timezone" fullWidth />
                            <TextInput source="latitude" fullWidth />
                            <TextInput source="longitude" fullWidth />
                            <NumberInput
                                source="default_zoom"
                                defaultValue={10}
                                validate={req}
                                isRequired
                                fullWidth
                            />
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.cities.fields.schedule')}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {translate('resources.cities.helpers.schedule')}
                            </Typography>
                            <table>
                                <tr>
                                    <th>
                                        {translate(
                                            'resources.cities.fields.schedule_h_day'
                                        )}
                                    </th>
                                    <th>
                                        {translate(
                                            'resources.cities.fields.schedule_h_value'
                                        )}
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        {translate(
                                            'resources.cities.fields.schedule_tuesday'
                                        )}
                                    </td>
                                    <td>
                                        <TextInput
                                            source="schedule_tuesday"
                                            label={false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {translate(
                                            'resources.cities.fields.schedule_wednesday'
                                        )}
                                    </td>
                                    <td>
                                        <TextInput
                                            source="schedule_wednesday"
                                            label={false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {translate(
                                            'resources.cities.fields.schedule_thursday'
                                        )}
                                    </td>
                                    <td>
                                        <TextInput
                                            source="schedule_thursday"
                                            label={false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {translate(
                                            'resources.cities.fields.schedule_friday'
                                        )}
                                    </td>
                                    <td>
                                        <TextInput
                                            source="schedule_friday"
                                            label={false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {translate(
                                            'resources.cities.fields.schedule_saturday'
                                        )}
                                    </td>
                                    <td>
                                        <TextInput
                                            source="schedule_saturday"
                                            label={false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {translate(
                                            'resources.cities.fields.schedule_sunday'
                                        )}
                                    </td>
                                    <td>
                                        <TextInput
                                            source="schedule_sunday"
                                            label={false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {translate(
                                            'resources.cities.fields.schedule_offdays'
                                        )}
                                    </td>
                                    <td>
                                        <TextInput
                                            source="schedule_offdays"
                                            label={false}
                                        />
                                    </td>
                                </tr>
                            </table>
                            <BooleanInput source="generate_to" fullWidth />
                            <BooleanInput source="generate_from" fullWidth />
                            <BooleanInput source="generate_any" fullWidth />
                            {/* <TextInput source="schedule" fullWidth /> */}
                            <Typography variant="body2" gutterBottom>
                                {translate(
                                    'resources.cities.helpers.sanctions_city_id'
                                )}
                            </Typography>
                            <ReferenceInput
                                source="sanctions_city_id"
                                reference="cities"
                            >
                                <AutocompleteInput
                                    optionText="title"
                                    fullWidth
                                />
                            </ReferenceInput>
                            <Typography variant="body2" gutterBottom>
                                {translate(
                                    'resources.cities.helpers.parent_city_id'
                                )}
                            </Typography>
                            <ReferenceInput
                                source="parent_city_id"
                                reference="cities"
                            >
                                <AutocompleteInput
                                    optionText="title"
                                    fullWidth
                                />
                            </ReferenceInput>
                            <ReferenceInput
                                source="cities_item_id"
                                reference="logix_cities"
                            >
                                <AutocompleteInput
                                    optionText="title_selector"
                                    validate={req}
                                    isRequired
                                    fullWidth
                                />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

// @ts-ignore
const EditTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityEdit;
