import * as React from 'react';
import {
    Edit,
    BooleanInput,
    TabbedForm,
    useTranslate,
    TextInput,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import SegmentsFormToolbar from './SegmentsFormToolbar';
import { RichTextInput } from '../components/RichTextInput';

const ShortcodesForm = () => {
    const translate = useTranslate();
    const formDefaults = {
        multiple: true,
        method: 'post',
    };
    return (
        <Edit resource="settings" redirect={false} mutationMode="pessimistic">
            <Box>
                <Typography variant="h4" padding={2}>
                    {translate('resources.settings.fieldGroups.shortcodes')}
                </Typography>
                <TabbedForm
                    defaultValues={formDefaults}
                    toolbar={<SegmentsFormToolbar />}
                    syncWithLocation={false}
                >
                    <TabbedForm.Tab label="resources.settings.fieldGroups.shortcode__tariffs_form">
                        <RichTextInput
                            source="CityPages__beforeIntercityTable"
                            label="resources.settings.values.city_pages__before_intercity_table"
                            fullWidth
                        />
                        <RichTextInput
                            source="CityPages__afterIntercityTable"
                            label="resources.settings.values.city_pages__after_intercity_table"
                            fullWidth
                        />
                        <RichTextInput
                            source="CityPages__beforeFromCityTable"
                            label="resources.settings.values.city_pages__before_from_city_table"
                            fullWidth
                        />
                        <RichTextInput
                            source="CityPages__afterFromCityTable"
                            label="resources.settings.values.city_pages__after_from_city_table"
                            fullWidth
                        />
                        <RichTextInput
                            source="CityPages__beforeToCityTable"
                            label="resources.settings.values.city_pages__before_to_city_table"
                            fullWidth
                        />
                        <RichTextInput
                            source="CityPages__afterToCityTable"
                            label="resources.settings.values.city_pages__after_to_city_table"
                            fullWidth
                        />
                        <BooleanInput
                            source="CityPages__updateCityPagesFromCityActive"
                            label="resources.settings.values.city_pages__update_city_pages_from_city_active"
                            fullWidth
                        />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="resources.settings.fieldGroups.shortcode__city_additional_list">
                        <RichTextInput
                            source="CityPages__beforeCityAdditionalList"
                            label="resources.settings.values.city_pages__before_city_additional_list"
                            fullWidth
                        />
                        <RichTextInput
                            source="CityPages__afterCityAdditionalList"
                            label="resources.settings.values.city_pages__after_city_additional_list"
                            fullWidth
                        />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="resources.settings.fieldGroups.shortcode__tk_additional_list">
                        <RichTextInput
                            source="TrasportCompanyPages__beforeCityAdditionalList"
                            label="resources.settings.values.transport_company_pages__before_city_additional_list"
                            fullWidth
                        />
                        <RichTextInput
                            source="TrasportCompanyPages__afterCityAdditionalList"
                            label="resources.settings.values.transport_company_pages__after_city_additional_list"
                            fullWidth
                        />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="resources.settings.fieldGroups.shortcode__oversized_conditions">
                        <TextInput
                            source="Shortcodes__oversizedConditionsTitle"
                            label="resources.settings.values.shortcodes__oversized_conditions_title"
                            fullWidth
                        />
                        <RichTextInput
                            source="Shortcodes__oversizedConditionsText"
                            label="resources.settings.values.shortcodes__oversized_conditions_text"
                            fullWidth
                        />
                    </TabbedForm.Tab>
                </TabbedForm>
            </Box>
        </Edit>
    );
};

export default ShortcodesForm;
