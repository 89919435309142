import * as React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    SelectField,
    TextInput,
    useTranslate,
    BulkDeleteButton,
    SelectInput,
    Translate,
} from 'react-admin';
import { TaskStatusChoices } from './TaskParams';

const entityFilters = (translate: Translate) => [
    <TextInput source="id" />,
    <SelectInput source="status" choices={TaskStatusChoices(translate)} />,
    <TextInput source="hook" />,
];

const ListBulkActionButtons = () => (
    <>
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);

const EntityList = () => {
    const translate = useTranslate();

    return (
        // @ts-ignore
        <List
            resource="tasks"
            filters={entityFilters(translate)}
            sort={{ field: 'planned_time', order: 'ASC' }}
            perPage={25}
        >
            <Datagrid
                optimized
                rowClick="show"
                bulkActionButtons={<ListBulkActionButtons />}
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" />
                <SelectField
                    source="status"
                    choices={TaskStatusChoices(translate)}
                />
                {/* <TextField source="log" /> */}
                <TextField source="hook" />
                <TextField source="author" />
                <DateField source="planned_time" showTime={true} />
                <DateField source="execution_ended_time" showTime={true} />
                <DateField source="execution_started_time" showTime={true} />
            </Datagrid>
        </List>
    );
};

export default EntityList;
