import * as React from 'react';
import { useTranslate } from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';

const SettingsList = () => {
    const translate = useTranslate();
    return (
        <Box>
            <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                <Grid item xs={12} md={8} minWidth={{ xs: '100%', xl: '80%' }}>
                    <Typography gutterBottom marginTop={1} variant="h6">
                        {translate('resources.settings.messages.indexStub')}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingsList;
