import * as React from 'react';
import { DateField, useTranslate, useRecordContext } from 'react-admin';
import { Typography, Card, CardContent, Box, Grid } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { Page } from '../../types';

const Aside = () => {
    const record = useRecordContext<Page>();
    return (
        <Box width={400} display={{ xs: 'none', lg: 'block' }}>
            {record && <EventList />}
        </Box>
    );
};

const EventList = () => {
    const record = useRecordContext<Page>();
    const translate = useTranslate();
    // const [locale] = useLocaleState();

    return (
        <Box ml={2}>
            <Card>
                <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                        <Grid item xs={6} display="flex" gap={1}>
                            <AccessTimeIcon fontSize="small" color="disabled" />
                            <Box flexGrow={1}>
                                <Typography variant="body2">
                                    {translate('resources.news.fields.created')}
                                </Typography>
                                <DateField
                                    record={record}
                                    source="created"
                                    showTime={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} display="flex" gap={1}>
                            <AccessTimeIcon fontSize="small" color="disabled" />
                            <Box flexGrow={1}>
                                <Typography variant="body2">
                                    {translate(
                                        'resources.news.fields.modified'
                                    )}
                                </Typography>
                                <DateField
                                    record={record}
                                    source="modified"
                                    showTime={true}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Aside;
