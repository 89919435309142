import * as React from 'react';
import {
    Edit,
    BooleanInput,
    TextInput,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    useRedirect,
    useTranslate,
    useRecordContext,
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { RichTextInput } from '../../components/RichTextInput';

import Aside from './Aside';
import FullNameField from './FullNameField';

const EntityCities = (props: any) => {
    const translate = useTranslate();
    const record = useRecordContext();

    return (
        <>
            <AutocompleteInput
                source="city_id"
                optionText="title"
                choices={record.cities}
                fullWidth
                onChange={props.onCityChange}
                helperText={translate('resources.pages.helpers.city_id')}
            />
            <ReferenceInput
                source="city_id"
                reference="cities"
                sort={{ field: 'title', order: 'DESC' }}
            >
                <AutocompleteInput
                    optionText="title"
                    fullWidth
                    onChange={props.onCityChange}
                    label={translate('resources.pages.fields.city_id__select')}
                    helperText={translate(
                        'resources.pages.helpers.city_id__select'
                    )}
                />
            </ReferenceInput>
        </>
    );
};

const EditToolbar = (props: any) => (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton mutationOptions={props.mutationOptions} />
        <DeleteButton
            mutationOptions={props.mutationOptions}
            mutationMode="pessimistic"
            redirect={props.onDelete}
        />
    </Toolbar>
);

const EntityEdit = () => {
    const translate = useTranslate();
    const redirect = useRedirect();

    const onCityChange = (value: number) => {
        const current_hash = window.location.hash
            .replace(/\?.*/, '')
            .replace('#', '');
        redirect(`${current_hash}?city_id=${value.toString()}`);
    };

    const extractFilter = () => {
        const search_params = window.location.hash.replace(
            /#.*?(\?(.*))?$/,
            '$1'
        );
        if (search_params) {
            const url_search_params = new URLSearchParams(search_params);

            return {
                city_id: url_search_params.get('city_id'),
            };
        }

        return undefined;
    };

    const onDelete = () => {
        const current_city = extractFilter()?.city_id ?? '';
        const current_hash = window.location.hash
            .replace(/\?.*/, '')
            .replace('#', '')
            .replace('/', '');

        if (current_city) {
            return `${current_hash}?city_id=`;
        } else {
            return 'pages/';
        }
    };

    return (
        <Edit
            title={<EditTitle />}
            aside={<Aside />}
            queryOptions={{ meta: { filter: extractFilter() } }}
            mutationOptions={{ meta: { filter: extractFilter() } }}
            redirect={false}
            mutationMode="pessimistic"
        >
            <SimpleForm
                toolbar={
                    <EditToolbar
                        mutationOptions={{ meta: { filter: extractFilter() } }}
                        onDelete={onDelete}
                    />
                }
            >
                <div>
                    <Grid
                        container
                        width={{ xs: '100%', xl: '80%' }}
                        spacing={2}
                    >
                        <Grid item xs={12} md={10} xl={12}>
                            <EntityCities onCityChange={onCityChange} />
                            <TextInput source="title" fullWidth />
                            <TextInput source="slug" fullWidth />
                            <BooleanInput source="active" fullWidth />
                            <TextInput
                                source="annotation"
                                fullWidth
                                multiline
                                maxRows={'10'}
                            />
                            <RichTextInput source="content" fullWidth />
                            <RichTextInput
                                source="additional_content_1"
                                fullWidth
                            />
                            <RichTextInput
                                source="additional_content_2"
                                fullWidth
                            />
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.pages.fieldGroups.seo')}
                            </Typography>
                            <TextInput source="seo_h1" fullWidth />
                            <TextInput
                                source="seo_description"
                                fullWidth
                                multiline
                                maxRows={'10'}
                            />
                            <BooleanInput source="seo_no_index" fullWidth />
                            <TextInput source="seo_canonical" fullWidth />
                        </Grid>
                    </Grid>
                </div>
            </SimpleForm>
        </Edit>
    );
};

// @ts-ignore
const EditTitle = () => <FullNameField size="32" sx={{ margin: '5px 0' }} />;

export default EntityEdit;
