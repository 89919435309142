import * as React from 'react';
import {
    useRecordContext,
    TextField,
    SimpleShowLayout,
    RichTextField,
    BooleanField,
} from 'react-admin';

import { Page } from '../types';

const Show = () => {
    const record = useRecordContext<Page>();
    if (!record) return null;

    return (
        <SimpleShowLayout>
            <TextField source="contact_id" />
            <TextField source="name" />
            <TextField source="site_name" />
            <TextField source="type" />
            <TextField source="phone" />
            <TextField source="address" />
            <TextField source="post_address" />
            <TextField source="email" />
            <TextField source="url" />
            <RichTextField source="description" />
            <TextField source="geo_id" />
            <TextField source="user_id" />
            <TextField source="city" />
            <TextField source="city_title" />
            <BooleanField source="active" />
            <BooleanField source="site_active" />
        </SimpleShowLayout>
    );
};

export default Show;
