import * as React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    SearchInput,
    TextInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import MobileGrid from './MobileGrid';
import Show from './Show';

const entityFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="contact_id" />,
    <TextInput source="geo_id" />,
    <TextInput source="site_name" />,
];

const EntityList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    // const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        // @ts-ignore
        <List
            filters={entityFilters}
            resource="logix_offices"
            sort={{ field: 'contact_id', order: 'DESC' }}
            perPage={25}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    expand={<Show />}
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField source="contact_id" />
                    <TextField source="site_name" />
                    <TextField source="geo_id" />
                    <TextField source="email" />
                    <TextField source="phone" />
                    <DateField source="date" />
                </Datagrid>
            )}
        </List>
    );
};

export default EntityList;
