import * as React from 'react';
import {
    Create,
    TextInput,
    SimpleForm,
    ReferenceInput,
    BooleanInput,
    NumberInput,
    AutocompleteInput,
    required,
    useTranslate,
} from 'react-admin';
import { Typography } from '@mui/material';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.title) {
        errors.title = 'ra.validation.required';
    }
    if (!values.cities_item_id) {
        errors.cities_item_id = 'ra.validation.required';
    }
    return errors;
};

const req = [required()];

const EntityCreate = () => {
    const translate = useTranslate();

    return (
        // @ts-ignore
        <Create>
            <SimpleForm
                sx={{ maxWidth: 500 }}
                // Here for the GQL provider
                defaultValues={{
                    fias_code: '',
                    title: '',
                    serviced_regions: '',
                }}
                validate={validateForm}
            >
                <TextInput source="title" validate={req} isRequired fullWidth />
                <TextInput source="domain" fullWidth />
                <TextInput source="slug" fullWidth />
                <BooleanInput
                    source="active"
                    fullWidth
                    defaultChecked
                    helperText={translate('resources.cities.helpers.active')}
                />
                <TextInput
                    type="email"
                    source="email"
                    validate={req}
                    isRequired
                    fullWidth
                />
                <NumberInput source="min_price" fullWidth />
                <TextInput source="delivery_time" fullWidth />
                <TextInput source="paid_entry" fullWidth />
                <TextInput source="terminal_name" fullWidth />
                <TextInput
                    source="wordform_vn"
                    fullWidth
                    validate={req}
                    isRequired
                />
                <TextInput
                    source="wordform_rod"
                    fullWidth
                    validate={req}
                    isRequired
                />
                <TextInput
                    source="wordform_dat"
                    fullWidth
                    validate={req}
                    isRequired
                />
                <TextInput
                    source="wordform_pr"
                    fullWidth
                    validate={req}
                    isRequired
                />
                {/* <TextInput source="additional" fullWidth /> */}
                <TextInput source="timezone" fullWidth />
                <TextInput source="latitude" fullWidth />
                <TextInput source="longitude" fullWidth />
                <NumberInput
                    source="default_zoom"
                    defaultValue={10}
                    validate={req}
                    isRequired
                    fullWidth
                />
                <BooleanInput source="generate_to" fullWidth />
                <BooleanInput source="generate_from" fullWidth />
                <BooleanInput source="generate_any" fullWidth />
                {/* <TextInput source="schedule" fullWidth /> */}
                <Typography variant="body2" gutterBottom>
                    {translate('resources.cities.helpers.sanctions_city_id')}
                </Typography>
                <ReferenceInput source="sanctions_city_id" reference="cities">
                    <AutocompleteInput optionText="title" fullWidth />
                </ReferenceInput>
                <Typography variant="body2" gutterBottom>
                    {translate('resources.cities.helpers.parent_city_id')}
                </Typography>
                <ReferenceInput source="parent_city_id" reference="cities">
                    <AutocompleteInput optionText="title" fullWidth />
                </ReferenceInput>
                <ReferenceInput
                    source="cities_item_id"
                    reference="logix_cities"
                >
                    <AutocompleteInput
                        optionText="title_selector"
                        fullWidth
                        validate={req}
                        isRequired
                    />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default EntityCreate;
