import * as React from 'react';
import { Create, SimpleForm, NumberInput, required, useTranslate } from 'react-admin';

const req = [required()];

const onRedirect = () => {
    return `settings/calculator/`;
};

const EntityCreate = () => {
    const translate = useTranslate();

    return (
        <>
            {/* @ts-ignore */}
            <Create
                resource="settings/serializable"
                mutationOptions={{
                    meta: {
                        namespace: 'Logistics',
                        path: 'calculation_insurance_prices',
                    },
                }}
                redirect={onRedirect}
            >
                <SimpleForm sx={{ maxWidth: { xl: '80%' } }}>
                    <NumberInput
                        source="limit"
                        label={translate(
                            'resources.calculation_insurance_prices.fields.limit'
                        )}
                        fullWidth
                        isRequired
                        validate={req}
                    />
                    <NumberInput
                        source="price"
                        label={translate(
                            'resources.calculation_insurance_prices.fields.price'
                        )}
                        fullWidth
                        isRequired
                        validate={req}
                    />
                </SimpleForm>
            </Create>
        </>
    );
};

export default EntityCreate;
