import * as React from 'react';
import { Edit, TextInput, SimpleForm, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import SegmentsFormToolbar from './SegmentsFormToolbar';

const SeoForm = () => {
    const translate = useTranslate();
    const formDefaults = {
        multiple: true,
        method: 'post',
    };
    return (
        <Edit resource="settings" redirect={false} mutationMode="pessimistic">
            <SimpleForm
                defaultValues={formDefaults}
                toolbar={<SegmentsFormToolbar />}
            >
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        minWidth={{ xs: '100%', xl: '80%' }}
                    >
                        <Typography variant="h4" gutterBottom>
                            {translate('resources.settings.fieldGroups.seo')}
                        </Typography>
                        <TextInput
                            source="AdsVisit__criteria"
                            label="resources.settings.values.adsvisit__criteria"
                            fullWidth
                        />
                        <TextInput
                            source="Logistics__body_scripts_insertion"
                            label="resources.settings.values.logistics__body_scripts_insertion"
                            fullWidth
                            multiline
                            maxRows={'10'}
                        />
                        <TextInput
                            source="Logistics__ogImage"
                            label="resources.settings.values.logistics__og_image"
                            fullWidth
                        />
                        <TextInput
                            source="Site__vk"
                            label="resources.settings.values.site__vk"
                            fullWidth
                        />
                        <TextInput
                            source="Site__instagram"
                            label="resources.settings.values.site__instagram"
                            fullWidth
                        />
                        <TextInput
                            source="Site__facebook"
                            label="resources.settings.values.site__facebook"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default SeoForm;
