import * as React from 'react';
import {
    useRecordContext,
    TextField,
    SimpleShowLayout,
    RichTextField,
    BooleanField,
} from 'react-admin';

import { Page } from '../types';

const Show = () => {
    const record = useRecordContext<Page>();
    if (!record) return null;

    return (
        <SimpleShowLayout>
            <TextField source="geo_id" />
            <TextField source="name" />
            <TextField source="type" />
            <TextField source="fias_id" />
            <TextField source="parent_id" />
            <TextField source="kind" />
            <TextField source="code" />
            <TextField source="direction" />
            <TextField source="timezone_offset" />
            <TextField source="kladr" />
            <RichTextField source="region_id" />
            <TextField source="district_id" />
            <TextField source="okrug_id" />
            <BooleanField source="active" />
        </SimpleShowLayout>
    );
};

export default Show;
