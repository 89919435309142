import * as React from 'react';
import {
    RichTextInput as BaseRichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
    ColorButtons,
    ImageButtons,
    DefaultEditorOptions,
} from 'ra-input-rich-text';
import { useTranslate } from 'ra-core';
import { useTiptapEditor } from 'ra-input-rich-text';
import { ToggleButton } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import Image from '@tiptap/extension-image';

const CustomEditorOptions = {
    ...DefaultEditorOptions,
    extensions: [
        ...(DefaultEditorOptions.extensions ?? []),
        Image.configure({
            inline: true,
            allowBase64: true,
        }),
    ],
};

const FileUploadButtons = (props: any) => {
    const translate = useTranslate();
    const editor = useTiptapEditor();

    const file_label = translate('ra.tiptap.image', { _: 'File Upload' });
    const image_label = translate('ra.tiptap.image', { _: 'Image Upload' });

    const uploadFile = React.useCallback(() => {
        var f = document.createElement('input');
        f.style.display = 'none';
        f.type = 'file';
        document.body.appendChild(f);
        f.click();
        f.onchange = function (event) {
            const files = (event.target as HTMLInputElement)?.files;
            if (!files?.length) {
                f.remove();
                return;
            }

            const file = files[0];
            const reader = new FileReader();
            reader.onload = function (e) {
                editor
                    .chain()
                    .focus()
                    .insertContent(
                        `<a href="download|${file.name}|${e?.target?.result}">${file.name}</a>`
                    )
                    .run();
            };
            reader.readAsDataURL(file);
            f.remove();
        };
    }, [editor, translate]);

    const uploadImage = React.useCallback(() => {
        var f = document.createElement('input');
        f.style.display = 'none';
        f.type = 'file';
        document.body.appendChild(f);
        f.click();
        f.onchange = function (event) {
            const files = (event.target as HTMLInputElement)?.files;
            if (!files?.length) {
                f.remove();
                return;
            }

            const file = files[0];
            const reader = new FileReader();
            reader.onload = function (e) {
                const result_src: string =
                    typeof e?.target?.result == 'string'
                        ? e?.target?.result
                        : '';
                if (result_src) {
                    editor
                        .chain()
                        .focus()
                        .setImage({ src: result_src, alt: file.name })
                        .run();
                }
            };
            reader.readAsDataURL(file);
            f.remove();
        };
    }, [editor, translate]);

    return editor ? (
        <>
            <ToggleButton
                aria-label={file_label}
                title={file_label}
                {...props}
                disabled={!editor?.isEditable}
                value="image"
                onClick={uploadFile}
            >
                <FolderCopyIcon fontSize="inherit" />
            </ToggleButton>
            <ToggleButton
                aria-label={image_label}
                title={image_label}
                {...props}
                disabled={!editor?.isEditable}
                value="image"
                onClick={uploadImage}
            >
                <PermMediaIcon fontSize="inherit" />
            </ToggleButton>
        </>
    ) : null;
};

const EditorToolbar = (props: any) => {
    return (
        <RichTextInputToolbar {...props}>
            <LevelSelect size={props?.size} />
            <FormatButtons size={props?.size} />
            <ColorButtons size={props?.size} />
            <AlignmentButtons size={props?.size} />
            <ListButtons size={props?.size} />
            <LinkButtons size={props?.size} />
            <ImageButtons size={props?.size} />
            <FileUploadButtons size={props?.size} />
            <QuoteButtons size={props?.size} />
            <ClearButtons size={props?.size} />
        </RichTextInputToolbar>
    );
};

export const RichTextInput = (props: any) => {
    return (
        <BaseRichTextInput
            editorOptions={CustomEditorOptions}
            toolbar={<EditorToolbar size={props?.size} />}
            {...props}
        />
    );
};
