import Icon from '@mui/icons-material/Plumbing';

import ActionsForm from './ActionsForm';

const resource = {
    icon: Icon,
    list: ActionsForm,
};

export default resource;
