import * as React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    BooleanInput,
    BooleanField,
    SearchInput,
    TextInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import MobileGrid from './MobileGrid';

const entityFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="id" />,
    <TextInput source="title" />,
    <TextInput source="slug" />,
    <BooleanInput source="active" />,
];

const EntityList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    // const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        // @ts-ignore
        <List
            filters={entityFilters}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField source="id" />
                    <TextField source="title" />
                    <BooleanField source="active" />
                    <TextField source="icon" />
                    <TextField source="city.title" />
                    <BooleanField source="is_all_cities" />
                    <DateField source="created" showTime={true} />
                    <DateField source="modified" showTime={true} />
                </Datagrid>
            )}
        </List>
    );
};

export default EntityList;
