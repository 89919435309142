import * as React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    SearchInput,
    TextInput,
} from 'react-admin';

import Show from './Show';

const entityFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="id" />,
    <TextInput source="title" />,
];

const EntityList = () => {
    return (
        // @ts-ignore
        <List
            filters={entityFilters}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
        >
            <Datagrid
                optimized
                expand={<Show />}
                sx={{
                    '& .column-groups': {
                        md: { display: 'none' },
                        lg: { display: 'table-cell' },
                    },
                }}
            >
                <TextField source="id" />
                <TextField source="title" />
                <DateField source="created" showTime={true} />
                <DateField source="modified" showTime={true} />
            </Datagrid>
        </List>
    );
};

export default EntityList;
