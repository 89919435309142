import Icon from '@mui/icons-material/Warehouse';

import List from './List';
// It's better to not allow to manually create/update logix offices

const resource = {
    list: List,
    icon: Icon,
};

export default resource;
