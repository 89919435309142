import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    useTranslate,
    AutocompleteArrayInput,
} from 'react-admin';
import default_sets from './default_sets';

const req = [required()];

const onRedirect = () => {
    return `settings/logix/`;
};

const EntityCreate = () => {
    const translate = useTranslate();

    return (
        <>
            {/* @ts-ignore */}
            <Create
                resource="settings/serializable"
                mutationOptions={{
                    meta: {
                        namespace: 'ApiLogix',
                        path: 'orders_filter_status_sets',
                    },
                }}
                redirect={onRedirect}
            >
                <SimpleForm sx={{ maxWidth: { xl: '80%' } }}>
                    <TextInput
                        source="label"
                        label={translate(
                            'resources.orders_filter_status_sets.fields.label'
                        )}
                        fullWidth
                        isRequired
                        validate={req}
                    />
                    <AutocompleteArrayInput
                        source="set"
                        choices={default_sets}
                        label={translate(
                            'resources.orders_filter_status_sets.fields.set'
                        )}
                        fullWidth
                        isRequired
                        validate={req}
                    />
                    <BooleanInput
                        source="front"
                        label={translate(
                            'resources.orders_filter_status_sets.fields.front'
                        )}
                        fullWidth
                        defaultValue={true}
                    />
                </SimpleForm>
            </Create>
        </>
    );
};

export default EntityCreate;
