import { CSSProperties } from 'react';
import { useMediaQuery, Theme } from '@mui/material';

import Welcome from './Welcome';
import GeneralForm from '../settings/GeneralForm';

const styles = {
    flexColumn: { display: 'flex', flexDirection: 'column' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Dashboard = () => {
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('lg')
    );

    // We render Welocme to show welcome message and
    // also GeneralForm from settings to trigger request to endpoint that
    // requires auth, so we authomatically be redirected to auth form
    // if don't have authorization
    return isXSmall ? (
        <div>
            <div style={styles.flexColumn as CSSProperties}>
                <Welcome />
                <GeneralForm hidden={true} />
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
                <Welcome />
                <GeneralForm hidden={true} />
            </div>
        </div>
    ) : (
        <>
            <Welcome />
            <GeneralForm hidden={true} />
        </>
    );
};

export default Dashboard;
