import * as React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    BooleanInput,
    SearchInput,
    TextInput,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

import MobileGrid from './MobileGrid';

const adminFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="username" />,
    <TextInput source="email" />,
    <BooleanInput source="is_active" />,
];

const AdminList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    // const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        // @ts-ignore
        <List
            filters={adminFilters}
            sort={{ field: 'username', order: 'DESC' }}
            perPage={25}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <TextField source="id" />
                    <TextField source="username" />
                    <TextField source="email" />
                    <TextField source="first_name" />
                    <TextField source="last_name" />
                    <DateField source="date_joined" />
                </Datagrid>
            )}
        </List>
    );
};

export default AdminList;
